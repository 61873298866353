<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a-tooltip placement="top">
          <template slot="title"><span>Add Product</span></template>
          <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-product-modal @click="$bvModal.show('add-update-product-modal'), resetForm()">
            <i class="fa fa-plus"/> Add Product
          </a>
        </a-tooltip>
        <table class="table table-sm table-bordered text-center" id="product_list">
          <thead>
          <tr>
            <th>Product ID</th>
            <th>Product Name</th>
            <th>Unit</th>
            <th>Price</th>
            <th>Total Price</th>
            <th>Threshold Limit(Warehouse | Hub)</th>
            <th style="width: 25%">Rack</th>
            <th v-if="user.role.includes('head_of_admin') || user.role.includes('software_admin') || user.role.includes('store_in_charge')">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product, index) in products" :key="index">
            <td>{{ product.id }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.unit }}</td>
            <td>{{ product.unit_price }}</td>
            <td>{{ currencyConverter(totalPrice(product.available_quantity, product.unit_price)) }}</td>
            <td>{{ product.warehouse_threshold_limit }} | {{ product.hub_threshold_limit }}</td>
            <td :style="product.rack_maps.length > 0 ? 'background-color: green; color: white' : ''" style="width: 25%">
              <template v-for="(rack_map, index) in product.rack_maps">
                <span class="item"  :key="index">
                    {{ rack_map.code }}
                    <span class="comma">
                        <br>
                    </span>
                </span>
              </template>
            </td>
            <td  v-if="user.role.includes('head_of_admin') || user.role.includes('software_admin') || user.role.includes('store_in_charge')">
              <a v-if="user.role.includes('head_of_admin') || user.role.includes('software_admin') || user.role.includes('store_in_charge')" class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(product.id), $bvModal.show('add-update-product-modal')"><i class="fa fa-edit mr-1"></i>Update</a>
              <a v-if="user.role.includes('head_of_admin')" class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(product.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="products.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-update-product
      :product="product"
      :btnEdit="btnEdit"
      :show="show"
      @getProducts="getProducts"
      @resetForm="resetForm"
      ref="addUpdateProduct">
    </add-update-product>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateProduct from '@/views/product/modal/addUpdateProduct'
import $ from 'jquery'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { Empty, addUpdateProduct },
  data() {
    return {
      products: [],
      product: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      show: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getProducts()
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    getProducts() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/products')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.products = response.data.products
          setTimeout(function() {
            $('#product_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.product = {}
      this.btnEdit = true
      apiClient.get('api/products/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.product = response.data.product
        }
      })
    },
    onDelete(productId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/products/' + productId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#product_list').DataTable().destroy()
              this.getProducts()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    totalPrice(availableQuantity, unitPrice) {
      return availableQuantity * unitPrice
    },
    currencyConverter(x) {
      return this.$localCurrency(x)
    },
    resetForm() {
      this.show = false
      this.btnEdit = false
      this.product = {}
    },
  },
}
</script>

<style scoped>
  .item:last-child .comma {
    display: none;
  }
</style>
