<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-product-modal" title="Product Add/Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Product Name <span class="custom-required">*</span></label>
              <input type="text" v-model="product.name" v-validate="'required'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter product name"/>
              <div class="invalid-feedback">Product name is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="unit" class="col-form-label col-form-label-sm">Product Unit <span class="custom-required">*</span></label>
              <input type="text" v-model="product.unit" v-validate="'required'" id="unit" name="unit" class="form-control form-control-sm" placeholder="Enter product unit"/>
              <div class="invalid-feedback">Product unit is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="unit_price" class="col-form-label col-form-label-sm">Product Price <span class="custom-required">*</span></label>
              <input type="text" v-model="product.unit_price" v-validate="'required|integer|min:0'" id="unit_price" name="unit_price" class="form-control form-control-sm" placeholder="Enter product price"/>
              <div class="invalid-feedback">Product price is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="category_id" class="col-form-label col-form-label-sm">Product Category <span class="custom-required">*</span></label>
              <select v-model="product.category_id" v-validate="'required'" id="category_id" name="category_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select category</option>
                <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.title }}</option>
              </select>
              <div class="invalid-feedback">Product category is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="priority" class="col-form-label col-form-label-sm">Product Priority <span class="custom-required">*</span></label>
              <input type="text" v-model="product.priority" v-validate="{ required: true }" id="priority" name="priority" class="form-control form-control-sm" placeholder="Enter product priority"/>
              <div class="invalid-feedback">Product priority is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="warehouse_threshold_limit" class="col-form-label col-form-label-sm">Warehouse Threshold Limit <span class="custom-required">*</span></label>
              <input type="number" v-model="product.warehouse_threshold_limit" v-validate="{ required: true, regex: /^[0-9]*$/ }" id="warehouse_threshold_limit" name="warehouse_threshold_limit" class="form-control form-control-sm" placeholder="Enter warehouse product threshold limit"/>
              <div class="invalid-feedback">Warehouse threshold limit is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="hub_threshold_limit" class="col-form-label col-form-label-sm">Hub Threshold Limit <span class="custom-required">*</span></label>
              <input type="number" v-model="product.hub_threshold_limit" v-validate="{ required: true, regex: /^[0-9]*$/ }" id="hub_threshold_limit" name="hub_threshold_limit" class="form-control form-control-sm" placeholder="Enter hub product threshold limit"/>
              <div class="invalid-feedback">Hub threshold limit is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="rack_maps" class="col-form-label col-form-label-sm">Product Rack Code <span class="custom-required">*</span></label>
              <v-select v-model="product.rack_maps" multiple  :class="errors.first('rack_maps') ? 'borderColor' : ''" name="rack_maps" id="rack_maps" v-validate="'required'" label="code" :options="rack_maps"></v-select>
              <div v-if="errors.first('rack_maps')" class="error">Product rock code is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="product_detail" class="col-form-label col-form-label-sm">Product Details</label>
              <textarea v-model="product.product_detail" name="product_detail" id="product_detail" cols="30" rows="2" class="form-control form-control-sm"></textarea>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" v-if="!this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="primary" v-if="this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-product-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'addUpdateProduct',
  props: ['product', 'btnEdit', 'show'],
  data() {
    return {
      categories: [],
      rack_maps: [],
      validation_errors: {},
      loader: false,
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    getCode() {
      apiClient.get('api/product/codes')
        .then(response => {
          const data = response.data
          this.categories = data.categories
          this.rack_maps = data.rack_maps
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/products', this.product).then(response => {
            this.loader = false
            this.$notification.success({
              message: response.data.message,
            })
            $('#product_list').DataTable().destroy()
            this.$emit('resetForm')
            this.$emit('getProducts')
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.put('api/products/' + this.product.id, this.product)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                $('#product_list').DataTable().destroy()
                this.$bvModal.hide('add-update-product-modal')
                this.$emit('getProducts')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: 'Update Failed',
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
  .error{
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .borderColor{
    border: 1px solid red !important;
    border-radius:4px !important;
  }
</style>
